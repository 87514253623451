.contacto{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    font-family: 'Mohave', sans-serif;
}

// .contacto__container{
//     border: 5px solid white;
//     padding: 3rem;
//     border-radius: 10px;
// }

.contacto__main-title{
    font-weight: bold;
    color: #fff;
    font-size: 4rem;
}

.contacto p{
    font-size: 2rem;
}

.contacto .email,
.contacto .phone{
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}