.inicio{
	min-width: 100vw;
	min-height: 100vh;
	max-height: 100vh;
	position: relative;
	overflow: hidden;
}

.cover{
	min-width: 100vw;
	min-height: 100vh;
	max-height: 100vh;
	max-width: 100vw;
	object-fit: cover;
	object-position: center;
	opacity: 0.25;
}


.content_container {
	position: absolute;
	top: 40%;
	left: 5%;
	z-index: 10;
	color: white;
	text-transform: uppercase;
}

.content_container .main_title {
	font-size: 16vh;
	font-family: 'Mohave', sans-serif;
}

.content_container .subtitle {
	font-size: 6vh;
	font-family: var(--lato-font-family);
	font-style: italic;
	color: white;
	margin-bottom: 6vh;
}

.content_container span {
	color: var(--primary-red);
}



@media only screen and (max-width: 600px) {
	.content_container .main_title {
		font-size: 30vmin;
	}

	.content_container .subtitle {
		font-size: 10vmin;
		text-align: center;
	}

	.inicio .primary_button{
		width: 90vw;
        text-align: center;
        text-transform: uppercase;
		display: block;
	}
}