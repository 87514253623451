@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Mohave:wght@700&family=Nothing+You+Could+Do&display=swap');

:root {
	// --primary-red: #a01917; 
	--primary-red: #df1600; 
	--background-black: rgb(25, 25, 25);
	--lato-font-family: 'Lato', sans-serif;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	max-width: 100vw;
	overflow-x: hidden;
	background-color: black;
	color: white;
}


.primary_button {
	background: var(--primary-red);
	color: white;
	padding: 2vmin;
	border-radius: 1.6vmin;
	font-size: 3vmin;
	font-weight: bold;
	cursor: pointer;
	transition: all 0.1s;
	border: none;
	text-transform: capitalize;
	text-decoration: none;
	font-family: var(--lato-font-family);
}

.primary_button:hover {
	color: rgb(200, 200, 200);
}

.primary_button:active {
	transform: translate(0, 10px);
}


@media only screen and (max-width: 600px) {
	.primary_button {
		padding: 4vmin;
		border-radius: 3.2vmin;
		font-size: 6vmin;
	}
}