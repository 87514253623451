.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 25;
	padding: 3vmin 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header .logo {
	margin-left: 5%;

	img {
		height: 10vmin;
	}
}

.header.transparent {
	background: transparent;
}

nav {
	margin-right: 5%;
	display: flex;
	align-items: center;

	a {
		color: white;
		text-decoration: none;
		font-family: var(--lato-font-family);
		opacity: 0.6;
		text-transform: uppercase;
		text-align: center;
		margin: 0 20px;

		&:hover {
			opacity: 1;
			color: var(--primary-red);
		}
	}
}

.navlink-active {
	opacity: 1;
}

@media only screen and (max-width: 600px) {
	.header .logo img {
		height: 5vh;
	}

	nav a {
		margin: 0 15px;
		font-size: 1.4vh;
	}
}
