.empresa{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    font-family: 'Mohave', sans-serif;
}

span{
    color: var(--primary-red);
}

.coverEmpresa{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

	min-width: 100vw;
	min-height: 100vh;
	max-height: 100vh;
	max-width: 100vw;
	object-fit: cover;
	object-position: top;
	opacity: 0.25;
    filter: grayscale(1);
}

.empresa__texts-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 90%;
    text-align: center;
}

.empresa__main-title{
    font-weight: bold;
    color: #fff;
    font-size: 16vmin;

}

.empresa p{
    font-size: 1.5rem;
    margin-bottom: 2rem;
}

.empresa .primary_button{
    margin-top: 2rem;
	padding: 1.25rem;
	border-radius: 1rem;
	font-size: 1.5rem;
}


@media only screen and (max-width: 600px) {
    .empresa p{
        text-align: center;
        font-size: 1rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .empresa .primary_button{
        padding: 1rem;
        border-radius: 0.75rem;
        font-size: 1rem;
        width: 90%;
        text-align: center;
        text-transform: uppercase;
    }

}