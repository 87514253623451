.galeria {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	font-family: 'Mohave', sans-serif;
	padding-top: 10vh;

	.images-container {
		// border: 5px dashed red;
		width: 75vw;

		display: flex;
		align-items: center;
		justify-content: center;
		align-content: center;
		flex-wrap: wrap;
		gap: 1vh;

		.img-container {
			width: 25%;
			// border: 4px dashed pink;
		}

		img {
			width: 100%;
			aspect-ratio: 1/1;
			object-fit: cover;
			object-position: center;
			filter: grayscale(100%) contrast(100%);
		}
	}
}

@media only screen and (max-width: 600px) {
	.galeria {
		.images-container {
			width: 80vw;

			.img-container {
				width: 100%;
			}
		}
	}
}
